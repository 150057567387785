.d-flex.mobile{
  width: 100%;
}
.suggestions-list {
  position: absolute;
  background-color: #161616;
  border: 1px solid #212123;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  max-width: 280px;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0 3px 18px #000;
  list-style: none;
  padding: 5px 10px;

  .suggestion-item {
    padding: 5px;
    cursor: pointer;
    font-size: 11px;
  }
  
  .suggestion-item:hover {
    color: #33d1d8;
  }

}
.app-search{
  width: 100%;
  padding-right: 15px !important;
  .search-row{
    display: flex;
    position: relative;
    padding-right: 5px;
    margin-left: 10px;
    align-items: center;
    border-radius: 5px;
    background: #0e0e10;
    height: 33px;
    min-width: 200px;

    .form-control{
      padding-left: 35px !important;
    }

    .reset-btn{
      position: absolute;
      right: 25px;
      top: 5px;
      background: transparent;
      border: 0;
      opacity: 1;
    }

    .bx-search-alt{
      left: 9px;
      top: -2px;
    }

    .search-btn{
      min-width: 25px;
      height: 25px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: transparent;
      border: 1px solid transparent;

      &:hover{
        background-color: #202020;
        border: 1px solid #3b3b3b;

      }
    }
  }  
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 83%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  .full-input {
    width: 90%;
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
  }
}

.mobile-search{
  position: relative;
  top: -5px;
  right: 0;


  .fast-close{
    background-color: #202020;
    border: 1px solid #383838;
    width: 17px;
    height: 17px;
    padding: 1px;
    margin: 0;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    line-height: 1;
    position: absolute;
    right: -4px;
    top: 6px;
  }

  .sugestion-label{
    display: block;
    position: relative;
    top: 6px;
    left: 41px;
    font-style: italic;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100vw - 307px);
    line-height: 18px;
  }

  .mobile-search-button{
    position: absolute;
    height: 28px;
    max-height: 28px;
    min-height: 28px;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    font-size: 21px;
    color: #fff !important;
    margin: auto;
    font-weight: 600;
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: #202020;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    padding: 0;
    justify-content: center;
  }
  .mobile-search-button.on{
    background-color: #33d1d8;
    border: 1px solid #33d1d8;
    svg{
      fill: #000
    }
  }
  .modal-content{
    background-color: transparent !important;
  }
  small{
    opacity: 1;
  }
}

.transparent-modal{
  .modal-content {
    background: transparent !important;
    border: none;
    box-shadow: none;
    height: auto;
    .modal-body{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 49px;
      .modal-search-row{
        display: flex;
        align-items: center;
        width: 100%;
        box-shadow: 0px 7px 16px 2px #000;
        gap: 10px;
        position: relative;

        .form-control{
          height: 50px;
          border-radius: 10px;
          border: 1px solid #121212;
        }

        .loader{
          position: absolute;
          right: 58px;
          top: 17px;
          left: auto;
          margin: auto;
        }

        .reset-btn{
          position: absolute;
          right: 50px;
          top: 14px;
          background: transparent;
          border: 0;
          opacity: .6;
        }

        .search-btn{
          height: 35px;
          min-width: 35px;
          font-size: 18px;
          border: 0;
          color: #253544 !important;
          margin: auto;
          font-weight: 600;
          display: flex;
          gap: 5px;
          align-items: center;
          padding: 0;
          border-radius: 8px;
          justify-content: center;
          position: absolute;
          top: 7px;
          right: 11px;
        }

        .suggestions-list {
          position: absolute;
          background-color: #161616;
          border: 1px solid #212123;
          max-height: 400px;
          overflow-y: auto;
          width: 100%;
          max-width: unset;
          z-index: 10;
          border-radius: 5px;
          box-shadow: 0 3px 18px #000;
          list-style: none;
          padding: 5px 10px;
          left: 0;
          right: 0;
          top: 8px;
        
          .suggestion-item {
            padding: 5px;
            cursor: pointer;
            font-size: 11px;
          }
          
          .suggestion-item:hover {
            color: #33d1d8;
          }
        
        }

      }
      .close{
        top: 4px;
        right: 3px;
        bottom: 19px;
        padding: 0;
        margin: 0;
        width: 40px;
        height: 40px;
        background: transparent;
        opacity: 1;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 10px;
        svg{
          width: 25px;
          height: auto;
        }
      }
    }
  }
}

.modal-backdrop.fade.show {
  @media screen and (max-width: 600px) {
    opacity: .75;
  } 
}

.btn-loader{
  position: relative;
  left: 0;
  span{
    left: 0 !important;
    span:nth-child(1){
      left: 0;
      opacity: 1 !important;
    }
    span:nth-child(2){
      left: 0;
      opacity: 0.3 !important;
    }    
  }
}