@charset "UTF-8";
.noneImg {
  width: 30px;
  height: 30px;
  background-color: #dfdfdf;
  color: #212227;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: bold;
}

.progres-bar {
  background: linear-gradient(270deg, #479a70 0%, #479a70 20%, #d8b744 40%, #d8b744 60%, #d04140 80%, #d04140 100%);
  width: 100%;
  height: 5px;
  border-radius: 10px;
  position: relative;
}
.progres-bar .point {
  position: absolute;
  background-color: #d9d9d9;
  border: 2px solid rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
  width: 10px;
  height: 10px;
  border-radius: 100px;
  top: -3px;
}

.procent {
  width: 40px;
  margin-left: 2px;
  text-align: right;
}

.token-names {
  display: flex;
  flex-direction: column;
  line-height: 18px;
}
.token-names .name-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3px;
}
.token-names .ticker-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3px;
}
.token-names .ticker-row .ticker {
  font-size: 10px;
  color: rgb(167, 173, 184);
  font-weight: bold;
  padding-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}

.table-responsive th {
  font-weight: 600;
  font-size: 12px;
}
.table-responsive tr:hover td {
  background: red !important;
}
.table-responsive tr td {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}
.table-responsive tr td > div:first-child, .table-responsive tr td > div > div:first-child {
  height: 28px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.table-responsive tr:nth-child(odd) td {
  background: rgba(103, 103, 103, 0.0117647059);
}

.index-zero {
  position: relative;
  top: 2px;
  font-size: 8px;
  padding: 0 2px 0 1px;
  font-weight: 600;
}

.to-right {
  justify-content: flex-end;
}

.complete {
  display: flex;
}
.complete div {
  width: 100%;
  text-align: center;
}

.btns {
  gap: 5px;
}
.btns .table-btn {
  border: 0;
  background-color: #212227;
  color: #fff;
  cursor: pointer;
  padding: 2px 10px;
  border-radius: 4px;
}
.btns .table-btn:hover {
  background-color: #1f2025;
}

.bonding-complete {
  background-color: #2d9863;
  color: #fff;
  font-weight: 500;
  padding: 3px 0;
  font-size: 11px;
}

.custom-modal-width {
  max-width: 400px; /* Ustaw szerokość modala na 500px */
}

.p_i {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 5px;
}

.header-title {
  text-align: left;
  display: flex;
  line-height: 1.2;
  position: unset;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  transition: all ease 0.1s;
}
.header-title .premium_icon {
  width: 10px;
}
.header-title:hover {
  opacity: 0.7;
}
.header-title .small {
  font-size: 9px;
  color: rgb(167, 173, 184);
  font-weight: 700;
  padding-top: 2px;
}

.settings {
  display: flex;
  justify-content: space-between;
  width: 100%;
  right: 18px;
  top: 21px;
  gap: 10px;
}
@media screen and (max-width: 600px) {
  .settings {
    gap: 0px;
  }
}
@media screen and (max-width: 600px) {
  .settings {
    flex-direction: column;
  }
}
.settings .switch {
  display: flex;
  gap: 5px;
}
.settings .switch span {
  font-size: 11px;
  color: rgb(83, 84, 89);
}
.settings .columns-visibility {
  margin: 0;
}
.settings .columns-visibility button {
  max-height: 25px;
  max-width: 25px;
  border: 0;
}
@media screen and (max-width: 600px) {
  .settings .columns-visibility button {
    background-color: #202020;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    width: 31px;
    height: 30px;
    max-height: unset;
    max-width: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: auto;
  }
  .settings .columns-visibility button:hover {
    background: #2b2b2b;
    transform: scale(0.95);
  }
  .settings .columns-visibility button:active {
    background: #434343;
    transform: scale(0.95);
  }
  .settings .columns-visibility button svg {
    min-width: 17px;
    min-height: 17px;
    color: #fff;
  }
  .settings .columns-visibility button:disabled {
    opacity: 0.4;
  }
}
.settings .refresh {
  margin-left: auto;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .settings .refresh {
    position: absolute;
    right: 0;
  }
}
.settings .refresh .bx-cog {
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: 2px;
  margin-left: 5px;
}
.settings .refresh .labelBTN {
  opacity: 0.4;
  font-size: 11px;
  padding: 0;
  border: 0;
  background: transparent;
}
.settings .refresh .refresh-value {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.settings .filter.disable {
  opacity: 0;
  transition: all ease 0.2s;
  visibility: hidden;
}
@media screen and (max-width: 600px) {
  .settings .filter.on {
    background: linear-gradient(to right, #181818 0%, #0e0e0e 100%);
    padding: 10px 15px 15px 15px;
    border: 0;
    margin-top: 0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    margin-bottom: 20px;
  }
}
.settings .filter {
  opacity: 1;
  visibility: visible;
  display: flex;
  gap: 10px;
  transition: all ease 0.2s;
}
@media screen and (min-width: 600px) {
  .settings .filter {
    display: flex !important;
  }
}
@media screen and (max-width: 600px) {
  .settings .filter {
    flex-direction: column;
  }
}
.settings .filter .mobile-control {
  display: none;
}
@media screen and (max-width: 600px) {
  .settings .filter .mobile-control {
    display: flex;
    width: 60px;
    background: transparent;
    border: 0;
    padding: 0;
    gap: 5px;
    align-items: center;
  }
  .settings .filter .mobile-control svg {
    font-size: 16px;
  }
}
.settings .filter .filters-box {
  display: flex;
  gap: 10px;
}
@media screen and (min-width: 600px) {
  .settings .filter .filters-box {
    display: flex !important;
  }
}
@media screen and (max-width: 600px) {
  .settings .filter .filters-box {
    flex-direction: column;
  }
}
.settings .filter .filters-box .checkboxes {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  height: 24px;
  box-sizing: border-box;
  font-size: 11px;
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  .settings .filter .filters-box .checkboxes {
    height: auto;
    gap: 12px;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: 0px;
    width: calc(100% - 50px);
    min-height: 33px;
    margin-bottom: 6px;
  }
}
@media screen and (max-width: 600px) {
  .settings .filter .filters-box .checkboxes span.reset-filters-mobile {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
@media screen and (max-width: 600px) {
  .settings .filter .filters-box .checkboxes label {
    margin-bottom: 0;
    font-size: 11px;
  }
}
.settings .filter .filters-box .input-wrapper {
  position: relative;
}
.settings .filter .filters-box .input-wrapper .filter-search {
  height: 25px;
  font-size: 11px;
  background: #0e0e10;
  border: 0;
  border-radius: 4px;
  padding: 0 8px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  min-width: 160px;
  max-width: 185px;
}
@media screen and (max-width: 600px) {
  .settings .filter .filters-box .input-wrapper .filter-search {
    min-width: unset;
    max-width: unset;
    height: 28px;
    font-size: 12px;
    background: #060606;
    border: 1px solid rgba(52, 52, 52, 0.76);
    border-radius: 4px;
    padding: 0px 8px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    width: 100%;
  }
}
.settings .filter .filters-box .input-wrapper .input-suffix {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 10px;
  color: rgba(186, 186, 186, 0.507);
}
.settings .filter .reset {
  background-color: transparent;
  font-size: 15px;
  border: 0;
  color: #5c6068;
  margin: auto;
  font-weight: 500;
  height: 25px;
  width: 25px;
  padding: 0;
  transition: all ease 0.2s;
  margin-left: -3px;
}
.settings .filter .reset:hover {
  background-color: #171717;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .settings .filter .reset {
    background-color: #dc1f1d;
    width: 100%;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    align-items: center;
    font-weight: 400;
  }
}
@media screen and (max-width: 600px) {
  .settings .filter .reset svg {
    display: none;
  }
}
.settings .filter .reset .mobile-label {
  display: none;
}
@media screen and (max-width: 600px) {
  .settings .filter .reset .mobile-label {
    display: flex;
  }
}
.settings .filters-options {
  display: flex;
  justify-content: space-between;
  padding: 5px 0 5px 0;
  margin-bottom: 10px;
}
.settings .filters-options .left {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  position: relative;
}
.settings .filters-options .left .dropdown-span {
  width: 100%;
  display: block;
  cursor: pointer;
}
.settings .filters-options .right {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}
.settings .filters-options .dropdown-settings {
  background-color: #202020;
  border: 1px solid #3b3b3b;
  border-radius: 5px;
  font-size: 11px;
  height: 30px;
  padding: 0 8px 0 5px;
}

.container-fluid {
  position: relative;
}

.btnSimple {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #2d9863;
}

.column-header-row {
  display: flex;
  gap: 2px;
  justify-content: flex-end;
}

.sort-desc,
.sort-asc {
  color: #2d9863 !important;
}

.sortArrow {
  min-width: 2px;
}

.title-with-icon {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 3px;
}
.title-with-icon svg {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto auto 0px 0;
  fill: #5c6068;
  cursor: pointer;
}

.title-with-icon.left {
  justify-content: flex-start;
}
.title-with-icon.left .header-title {
  text-align: left;
}
.title-with-icon.left svg {
  position: relative;
  top: 1px;
  right: 3px;
  margin: 0;
}

.title-with-icon.last svg {
  position: relative;
  top: 1px;
  right: 3px;
  margin: 0;
}

.btnSimple {
  display: flex;
}
.btnSimple .running-label {
  display: flex;
  width: 75px;
  flex-direction: row;
  justify-content: flex-end;
}

input.filter-search::-webkit-outer-spin-button,
input.filter-search::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bubbles img {
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}
.bubbles img:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

.no-data {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
  text-align: center;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #202020;
}

.mobile-btn, .btnLegend {
  background-color: #202020;
  border: 1px solid #3b3b3b;
  border-radius: 5px;
  width: 31px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}
.mobile-btn:hover, .btnLegend:hover {
  background: #2b2b2b;
  transform: scale(0.95);
}
.mobile-btn:active, .btnLegend:active {
  background: #434343;
  transform: scale(0.95);
}
.mobile-btn svg, .btnLegend svg {
  min-width: 17px;
  min-height: 17px;
  color: #fff;
}
.mobile-btn:disabled, .btnLegend:disabled {
  opacity: 0.4;
}

.mobile-btn.on {
  background-color: #33d1d8;
  border: 1px solid #10eef7;
}
.mobile-btn.on svg {
  color: #000 !important;
}

.legend-box {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.legend-box ul {
  padding: 0 0 0 15px;
}
.legend-box > div:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.legend-box .legend-item {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
  grid-template-areas: ". .";
  font-size: 12px;
  border-bottom: 1px solid #19191c;
  padding-bottom: 10px;
}
.legend-box .legend-item .legend-label {
  font-weight: bold;
}
.legend-box .legend-item .legend-value {
  font-weight: 300;
}

.tronlink-btn {
  font-size: 11px;
  background: #ff0000 !important;
  border: 0;
}

.sun-btn {
  font-size: 11px;
  background: #fab240 !important;
  color: #000;
  border: 0;
}

.tronlink-btn,
.sun-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modal-body label {
  font-size: 11px;
}
@media screen and (max-width: 600px) {
  .modal-body label {
    font-size: 10px !important;
  }
}
.modal-body .checkbox-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.modal-body .checkbox-list label {
  width: 33%;
}
@media screen and (max-width: 600px) {
  .modal-body .checkbox-list label {
    width: 50%;
  }
}

.loading {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: center;
  flex-direction: column;
}

.loader {
  position: absolute;
  left: 10px;
}
.loader span {
  top: unset !important;
  left: unset !important;
}
.loader span span:nth-child(1) {
  top: 5px !important;
  left: unset !important;
}
.loader span span:nth-child(2) {
  top: unset !important;
}

@keyframes waveColor {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}
.wave-text, .wave-text small {
  background: linear-gradient(90deg, rgb(51, 209, 216) 15%, rgb(222, 248, 249) 35%, rgb(255, 255, 255) 50%, rgb(202, 243, 245) 65%, rgb(51, 209, 216) 85%);
  background-size: 200% 100%; /* Ustawienie szerokości gradientu na 200% */
  background-position: -100% 0; /* Początkowe przesunięcie gradientu */
  color: transparent; /* Kolor tekstu musi być przezroczysty, aby gradient był widoczny */
  -webkit-background-clip: text; /* Tylko tekst będzie wyświetlany w gradientowym tle */
  animation: waveColor 0.6s infinite linear; /* Animacja przesuwa gradient od lewej do prawej */
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4196078431);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1176470588);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.168627451);
}

.main-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
  gap: 10px;
  color: #4e4e4e;
}/*# sourceMappingURL=styles.css.map */