.link{
  background: transparent;
  border: 0;
  padding: 0;
  transition: all ease-in-out .05s;
  &:hover{
    color: #2d9863
  }
}

.policy{
  padding: 20px 0;
  h1{
    margin: 0;
  }
  h2 {
    color: #ffffff;
    font-size: 15px;
    margin: 20px 0;
    font-weight: bold;
  }
  .section {
    margin: 0 0 20px 0;
    padding: 0;
    .cytat{
      font-style: italic;
      font-size: 14px;
      padding: 0 20px;
    }
  }
  .section p, .section li {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
  }
  .section li {
    margin: 0 0 10px 0 ;
  }
  .acceptance {
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #2c3e50;
      background-color: #ecf0f1;
  }
}