// body{background: #000 !important;}
.login{
  // background-color: #253544;
  background-color: #000000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;
  .login-column{
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 80px;
    gap: 0px 0px;
    grid-template-areas:
        "."
        "."
        ".";
    height: 100%;
    justify-items: center;
    padding-bottom: 30px;
    .logo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;
      .hero {
        font-weight: 100;
        color: #959595;
        display: flex;
        gap: 13px;
        font-size: 25px;
        margin-top: 12px;
      } 
    }
    .login-buttons{
      display: flex;
      gap: 10px;
      .btn-login[disabled] {
        background-color: #b9b9b9 !important;
      }
      .btn-login, .btn-login-black{
        background-color: #33d1d8;
        font-size: 13px;
        border: 0;
        color: #253544;
        margin: auto;
        font-weight: 600;
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 6px 12px 6px 20px;

        &:hover{
          background-color: #01b8cc;
        }
      }
      .btn-login-black{
        padding: 6px 12px;
        background-color: #252424;
        color: #fff;
        font-weight: 500;
        &:hover{
          background-color: #2b2b2b;
        }
      }
    }


    .top-bar{
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 25px;
      height: 90px;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
        gap: 15px;
      } 
      .logotype{
        img{
          width: 180px;
        }
      }
    }
  }
}

.login-contnent{
  .content-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 63%;
    margin: auto;
    @media screen and (max-width: 600px) {
      max-width: 90%;
    } 
    h1 {
      font-size: 36px;
      font-weight: bold;
      background: #0081DB;
      background: linear-gradient(to right, #0081DB 0%, #01E9BD 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media screen and (max-width: 600px) {
        font-size: 25px;
        margin-bottom: 20px;
      } 
    }   
    p{
      font-size: 13px;
      @media screen and (max-width: 600px) {
        font-size: 11px;
      } 
    } 
  }
  .points{
    display: flex;
    justify-content: center;
    gap: 50px;
    max-width: 80%;
    margin: auto;
    margin-top: 15px;
    @media screen and (max-width: 1500px) {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 0px;
      grid-template-areas:
      ". ."
      ". .";
      justify-items: start;
      max-width: 600px;
    }
    @media screen and (max-width: 600px) {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 5px;
      grid-template-areas: ".";
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
      justify-items: center;
      text-align: center;
      margin-top: 10px;
    }
    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 10px;
      @media screen and (max-width: 1500px) {
        margin-bottom: 10px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: auto;
        gap: 5px;
      }
      li{
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.1;
        @media screen and (max-width: 600px) {
          font-size: 12px;
          line-height: 1.3;
        }
        .point-circle{
          width: 7px;
          height: 7px;
          border-radius: 20px;
          background-color: #01e9bd;
          display: inline-block;
          position: relative;
          left: -6px;
          @media screen and (max-width: 600px) {
            width: 5px;
            height: 5px;
            left: -13px;
            margin-right: -5px;
          }
        }
      }
      .star{
        cursor: context-menu;
        .starbox{
          display: inline-block;
          img{
            position: relative;
            width: 10px;
            right: -4px;
            top: -2px;
          }
        }
      }
    }
  }
  .whyscanmeme{
    max-width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    
    h2{
      font-size: 27px;
      font-weight: bold;
      background: #0081DB;
      background: linear-gradient(to right, #0081DB 0%, #01E9BD 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media screen and (max-width: 1250px) {
        margin-bottom: 30px;
      }
      @media screen and (max-width: 600px) {
        text-align: center;
        margin-bottom: 0px;
        margin-top: 30px;
        font-size: 25px;
      }
    }

    .premium{
      display: flex;
      gap: 30px;
      margin: 30px 0;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      } 
      .premium-item.bg1{
        background: rgb(1,187,203);
        background: linear-gradient(270deg, rgba(1,187,203,1) 0%, rgba(0,154,213,1) 100%);
      }
      .premium-item.bg2{
        background: rgb(1,191,202);
        background: linear-gradient(270deg, rgba(1,221,193,1) 0%, rgba(1,191,202,1) 100%);
      }

      .premium-item{
        position: relative;
        display: flex;
        gap: 15px;
        max-width: 510px;
        border-radius: 8px;
        padding: 20px;
        b{
          font-size: 14px;
          color: #000;
        }
        p{
          font-size: 10px;
          color: #000;
          margin: 0;
          padding: 0;
          font-weight: 500;
          line-height: 13px;
        }
        .premium-sign{
          background-color: #000000;
          color: #fff;
          border-radius: 5px;
          position: absolute;
          right: 10px;
          top: 10px;
          padding: 2px 11px;
          font-size: 10px;
          font-weight: bold;
          @media screen and (max-width: 600px) {
            border-radius: 0;
            right: -4px;
            top: -1px;
          }
        }
      }
    }

    .flex {
      display: flex;
      gap: 30px;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 0;
      } 
      .grid-item {
        margin-top: 20px;
        display: flex;
        gap: 15px;
        .icon{
          img {
            @media screen and (max-width: 600px) {
              width: 43px;
            } 
          }
        }
        div{
          b{
            font-size: 14px;
          }
          p{
            font-size: 10px;
          }          
        }

      }
    }


  }
  .join-us{
    width: 100%;
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    text-align: center;
    @media screen and (max-width: 600px) {
      margin-top: 35px;
      margin: 30px 0;
      padding: 0 30px;
    } 
    h3{
      font-size: 20px;
      font-weight: bold;
      background: #0081DB;
      background: linear-gradient(to right, #0081DB 0%, #01E9BD 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p{
      @media screen and (max-width: 600px) {
        font-size: 11px;
      } 
    }

  }
}

.footer-title{
  color: #787878;
  font-size: 12px;
  letter-spacing: .3px;
  .link-btn{
    color: #787878;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    &:hover{
      color: #01b8cc;
      text-decoration: underline;
    }
  }
}  

.guide{
  padding: 0;
  .section {
    margin: 0 0 20px 0;
    padding: 0;
  }
  .note {
    margin: 0 20px;
    padding: 0;
    b{
      font-size: 15px;
    }
  }
  .tier{
    background: rgb(1, 187, 203);
    background: linear-gradient(270deg, #01bbcb 0%, rgb(0, 154, 213) 100%);
    position: relative;
    display: flex;
    gap: 20px;
    border-radius: 8px;
    padding: 20px 20px 20px 15px;
    color: #000;
    flex-direction: row;
    align-items: flex-start;
    p{
      color: #000 !important;
      font-weight: 500;
      line-height: 1.3;
    }
    b{
      font-size: 18px;
      color: #fff !important;
    }
    .icon{
      min-width: 50px;
    }
  }

  .section{
    p, li{
      margin: 0;
      font-size: 12px;  
      color: #fff;    
    }
    a{
      color: #33d1d8;
    }
    b{
      font-weight: bold;
      color: #fff;
    }
  }
  .acceptance {
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #2c3e50;
      background-color: #ecf0f1;
  }
}


@keyframes enter {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes leave {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.animate-enter,
.animate-leave {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  b{
    font-size: 13px;
    padding: 1px 4px;
    border-radius: 5px;
    margin-top: 12px;
    color: #0081DB;
  }
}

.animate-enter {
  animation: enter 0.3s forwards;
}

.animate-leave {
  animation: leave 0.3s forwards;
}
.closebtn{
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  background: transparent;
  border: 0;
  color: #000;
  fill: #000;
}

.closebtn2{
  background: linear-gradient(270deg, rgb(1, 187, 203) 0%, rgb(0, 154, 213) 100%);
  margin: 23px 0 15px 0;
  padding: 10px 20px;
  border: 0;
  color: #000;
  font-weight: 700;
  border-radius: 8px;
}

.login-footer {
  @media screen and (max-width: 600px) {
    margin: 0 !important;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    background: #0a0a0a;
    padding: 10px 0;
    height: 65px;
  } 
  p{
    @media screen and (max-width: 600px) {
      margin: 0 !important;
    } 
  }
}