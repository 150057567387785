.slider {
  width: 100%;
  position: relative;
  overflow: hidden;
  top: -5px;
}
.slider .react-marquee-line-hor {
  height: 100%;
  display: flex;
  align-items: center;
}
.slider .react-marquee-line-hor .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
}

.profil-menu {
  min-width: 130px;
}
@media screen and (max-width: 1200px) {
  .profil-menu {
    min-width: unset;
  }
}

@media screen and (max-width: 600px) {
  .d-flex.logotype {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .navbar-header {
    padding-top: 33px !important;
    padding-right: 0 !important;
    height: 90px !important;
  }
}

@media screen and (max-width: 600px) {
  .slider {
    position: absolute;
    top: -7px;
    background: linear-gradient(to right, #181818 0%, #0e0e0e 100%);
  }
}

.user-menu .switch {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.loader-topbar {
  display: flex;
  gap: 5px;
  color: #656464;
  position: relative;
  top: 5px;
  align-items: center;
  height: 28px;
  justify-content: center;
}

.loader-gauge-chart {
  display: flex;
  align-items: center;
  padding: 0 8px 0 20px;
}

.mask {
  background: linear-gradient(90deg, rgb(0, 0, 0) 1%, rgba(255, 255, 255, 0) 4%, rgba(255, 255, 255, 0) 96%, rgb(0, 0, 0) 99%);
  width: 100%;
  min-height: 50px;
  position: absolute;
  z-index: 9;
  pointer-events: none;
}/*# sourceMappingURL=header.css.map */