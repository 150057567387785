.item{
  height: 35px;
}
.ticker-item{
  padding-right: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 4px;
  line-height: 1;
  padding-top: 11px;
  align-items: center;
  cursor: pointer;
}
.item-from{
  font-size: 9px;
}
.item-label{
  color: #ffffff;
}