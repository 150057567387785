.link {
  background: transparent;
  border: 0;
  padding: 0;
  transition: all ease-in-out 0.05s;
}
.link:hover {
  color: #2d9863;
}

.policy {
  padding: 20px 0;
}
.policy h1 {
  margin: 0;
}
.policy h2 {
  color: #ffffff;
  font-size: 15px;
  margin: 20px 0;
  font-weight: bold;
}
.policy .section {
  margin: 0 0 20px 0;
  padding: 0;
}
.policy .section .cytat {
  font-style: italic;
  font-size: 14px;
  padding: 0 20px;
}
.policy .section p, .policy .section li {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
}
.policy .section li {
  margin: 0 0 10px 0;
}
.policy .acceptance {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #2c3e50;
  background-color: #ecf0f1;
}/*# sourceMappingURL=footer.css.map */