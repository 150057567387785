.profil-menu{
  display: flex;
  align-items: center;
  justify-content: center;
  .tier-drop{
    button.tier{
      height: 26px;
      background-color: #33d1d8;
      font-size: 13px;
      border: 0;
      color: #253544 !important;
      margin: auto;
      font-weight: 600;
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 3px 12px 3px 10px;
      border-radius: 5px;
      width: auto;
      span{
        color: #253544;
        font-weight: 600;
        letter-spacing: -0.2px;
        font-size: 12px;
        display: block !important;
      }
      i{
        color: #253544;    
        font-size: 17px;
      }
    } 
    button.tier.mobile{
      background-color: transparent;
      padding: 0;
      margin: 0;
      span{
        color: #ffffff;
        padding: 0px 0 0 5px !important;
        margin: 0 !important;
        i{
          color: #ffffff; 
          margin: -3px 0 0 0;  
          padding: 0;
          &:before{
            font-size: 21px;
          } 
        }
      }
    } 
  }  
}
