.login {
  background-color: #000000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.login .login-column {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 80px;
  gap: 0px 0px;
  grid-template-areas: "." "." ".";
  height: 100%;
  justify-items: center;
  padding-bottom: 30px;
}
.login .login-column .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
}
.login .login-column .logo .hero {
  font-weight: 100;
  color: #959595;
  display: flex;
  gap: 13px;
  font-size: 25px;
  margin-top: 12px;
}
.login .login-column .login-buttons {
  display: flex;
  gap: 10px;
}
.login .login-column .login-buttons .btn-login[disabled] {
  background-color: #b9b9b9 !important;
}
.login .login-column .login-buttons .btn-login, .login .login-column .login-buttons .btn-login-black {
  background-color: #33d1d8;
  font-size: 13px;
  border: 0;
  color: #253544;
  margin: auto;
  font-weight: 600;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 6px 12px 6px 20px;
}
.login .login-column .login-buttons .btn-login:hover, .login .login-column .login-buttons .btn-login-black:hover {
  background-color: #01b8cc;
}
.login .login-column .login-buttons .btn-login-black {
  padding: 6px 12px;
  background-color: #252424;
  color: #fff;
  font-weight: 500;
}
.login .login-column .login-buttons .btn-login-black:hover {
  background-color: #2b2b2b;
}
.login .login-column .top-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  height: 90px;
}
@media screen and (max-width: 600px) {
  .login .login-column .top-bar {
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    gap: 15px;
  }
}
.login .login-column .top-bar .logotype img {
  width: 180px;
}

.login-contnent .content-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 63%;
  margin: auto;
}
@media screen and (max-width: 600px) {
  .login-contnent .content-title {
    max-width: 90%;
  }
}
.login-contnent .content-title h1 {
  font-size: 36px;
  font-weight: bold;
  background: #0081DB;
  background: linear-gradient(to right, #0081DB 0%, #01E9BD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 600px) {
  .login-contnent .content-title h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }
}
.login-contnent .content-title p {
  font-size: 13px;
}
@media screen and (max-width: 600px) {
  .login-contnent .content-title p {
    font-size: 11px;
  }
}
.login-contnent .points {
  display: flex;
  justify-content: center;
  gap: 50px;
  max-width: 80%;
  margin: auto;
  margin-top: 15px;
}
@media screen and (max-width: 1500px) {
  .login-contnent .points {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: ". ." ". .";
    justify-items: start;
    max-width: 600px;
  }
}
@media screen and (max-width: 600px) {
  .login-contnent .points {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 5px;
    grid-template-areas: ".";
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    justify-items: center;
    text-align: center;
    margin-top: 10px;
  }
}
.login-contnent .points ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 10px;
}
@media screen and (max-width: 1500px) {
  .login-contnent .points ul {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .login-contnent .points ul {
    margin-bottom: auto;
    gap: 5px;
  }
}
.login-contnent .points ul li {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.1;
}
@media screen and (max-width: 600px) {
  .login-contnent .points ul li {
    font-size: 12px;
    line-height: 1.3;
  }
}
.login-contnent .points ul li .point-circle {
  width: 7px;
  height: 7px;
  border-radius: 20px;
  background-color: #01e9bd;
  display: inline-block;
  position: relative;
  left: -6px;
}
@media screen and (max-width: 600px) {
  .login-contnent .points ul li .point-circle {
    width: 5px;
    height: 5px;
    left: -13px;
    margin-right: -5px;
  }
}
.login-contnent .points ul .star {
  cursor: context-menu;
}
.login-contnent .points ul .star .starbox {
  display: inline-block;
}
.login-contnent .points ul .star .starbox img {
  position: relative;
  width: 10px;
  right: -4px;
  top: -2px;
}
.login-contnent .whyscanmeme {
  max-width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}
.login-contnent .whyscanmeme h2 {
  font-size: 27px;
  font-weight: bold;
  background: #0081DB;
  background: linear-gradient(to right, #0081DB 0%, #01E9BD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 1250px) {
  .login-contnent .whyscanmeme h2 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 600px) {
  .login-contnent .whyscanmeme h2 {
    text-align: center;
    margin-bottom: 0px;
    margin-top: 30px;
    font-size: 25px;
  }
}
.login-contnent .whyscanmeme .premium {
  display: flex;
  gap: 30px;
  margin: 30px 0;
}
@media screen and (max-width: 600px) {
  .login-contnent .whyscanmeme .premium {
    flex-direction: column;
  }
}
.login-contnent .whyscanmeme .premium .premium-item.bg1 {
  background: rgb(1, 187, 203);
  background: linear-gradient(270deg, rgb(1, 187, 203) 0%, rgb(0, 154, 213) 100%);
}
.login-contnent .whyscanmeme .premium .premium-item.bg2 {
  background: rgb(1, 191, 202);
  background: linear-gradient(270deg, rgb(1, 221, 193) 0%, rgb(1, 191, 202) 100%);
}
.login-contnent .whyscanmeme .premium .premium-item {
  position: relative;
  display: flex;
  gap: 15px;
  max-width: 510px;
  border-radius: 8px;
  padding: 20px;
}
.login-contnent .whyscanmeme .premium .premium-item b {
  font-size: 14px;
  color: #000;
}
.login-contnent .whyscanmeme .premium .premium-item p {
  font-size: 10px;
  color: #000;
  margin: 0;
  padding: 0;
  font-weight: 500;
  line-height: 13px;
}
.login-contnent .whyscanmeme .premium .premium-item .premium-sign {
  background-color: #000000;
  color: #fff;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 2px 11px;
  font-size: 10px;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .login-contnent .whyscanmeme .premium .premium-item .premium-sign {
    border-radius: 0;
    right: -4px;
    top: -1px;
  }
}
.login-contnent .whyscanmeme .flex {
  display: flex;
  gap: 30px;
}
@media screen and (max-width: 600px) {
  .login-contnent .whyscanmeme .flex {
    flex-direction: column;
    gap: 0;
  }
}
.login-contnent .whyscanmeme .flex .grid-item {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}
@media screen and (max-width: 600px) {
  .login-contnent .whyscanmeme .flex .grid-item .icon img {
    width: 43px;
  }
}
.login-contnent .whyscanmeme .flex .grid-item div b {
  font-size: 14px;
}
.login-contnent .whyscanmeme .flex .grid-item div p {
  font-size: 10px;
}
.login-contnent .join-us {
  width: 100%;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .login-contnent .join-us {
    margin-top: 35px;
    margin: 30px 0;
    padding: 0 30px;
  }
}
.login-contnent .join-us h3 {
  font-size: 20px;
  font-weight: bold;
  background: #0081DB;
  background: linear-gradient(to right, #0081DB 0%, #01E9BD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 600px) {
  .login-contnent .join-us p {
    font-size: 11px;
  }
}

.footer-title {
  color: #787878;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.footer-title .link-btn {
  color: #787878;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
}
.footer-title .link-btn:hover {
  color: #01b8cc;
  text-decoration: underline;
}

.guide {
  padding: 0;
}
.guide .section {
  margin: 0 0 20px 0;
  padding: 0;
}
.guide .note {
  margin: 0 20px;
  padding: 0;
}
.guide .note b {
  font-size: 15px;
}
.guide .tier {
  background: rgb(1, 187, 203);
  background: linear-gradient(270deg, #01bbcb 0%, rgb(0, 154, 213) 100%);
  position: relative;
  display: flex;
  gap: 20px;
  border-radius: 8px;
  padding: 20px 20px 20px 15px;
  color: #000;
  flex-direction: row;
  align-items: flex-start;
}
.guide .tier p {
  color: #000 !important;
  font-weight: 500;
  line-height: 1.3;
}
.guide .tier b {
  font-size: 18px;
  color: #fff !important;
}
.guide .tier .icon {
  min-width: 50px;
}
.guide .section p, .guide .section li {
  margin: 0;
  font-size: 12px;
  color: #fff;
}
.guide .section a {
  color: #33d1d8;
}
.guide .section b {
  font-weight: bold;
  color: #fff;
}
.guide .acceptance {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #2c3e50;
  background-color: #ecf0f1;
}

@keyframes enter {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes leave {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
.animate-enter,
.animate-leave {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 5px;
}
.animate-enter b,
.animate-leave b {
  font-size: 13px;
  padding: 1px 4px;
  border-radius: 5px;
  margin-top: 12px;
  color: #0081DB;
}

.animate-enter {
  animation: enter 0.3s forwards;
}

.animate-leave {
  animation: leave 0.3s forwards;
}

.closebtn {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  background: transparent;
  border: 0;
  color: #000;
  fill: #000;
}

.closebtn2 {
  background: linear-gradient(270deg, rgb(1, 187, 203) 0%, rgb(0, 154, 213) 100%);
  margin: 23px 0 15px 0;
  padding: 10px 20px;
  border: 0;
  color: #000;
  font-weight: 700;
  border-radius: 8px;
}

@media screen and (max-width: 600px) {
  .login-footer {
    margin: 0 !important;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    background: #0a0a0a;
    padding: 10px 0;
    height: 65px;
  }
}
@media screen and (max-width: 600px) {
  .login-footer p {
    margin: 0 !important;
  }
}/*# sourceMappingURL=login.css.map */