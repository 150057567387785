.token {
  width: 100%;
  margin-bottom: 50px;
  position: relative;
}
.token .noneImg, .token .tokenImg {
  width: 100%;
  height: 29vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  min-height: 245px;
  max-height: 350px;
}
.token .start-time {
  position: absolute;
  right: 0;
  top: 20px;
  background-color: #33d1d8;
  color: #000;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 15px;
  font-size: 16px;
  border-radius: 8px 0 0 8px;
  gap: 0;
  line-height: 1.3;
  font-weight: 800;
}
.token .start-time span {
  font-size: 12px;
  opacity: 0.75;
  font-weight: 600;
}
.token .tab-body {
  display: flex;
  flex-direction: column;
  position: relative;
}
.token .tab-body .socila-on-photo {
  position: absolute;
  left: -1px;
  top: -37px;
  background: #000000;
  padding: 10px 18px 10px 0px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  border-radius: 0 8px 0 0;
}
.token .tab-body .socila-on-photo button {
  border: 0;
  width: 15px;
}
.token .tab-body .socila-on-photo button img {
  width: 18px;
}
.token .tab-body .token-title-row {
  height: 40px;
  align-items: center;
  font-weight: 800;
  margin-bottom: 12px;
  margin-top: 5px;
}
.token .tab-body .token-title-row, .token .tab-body .token-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
}
.token .tab-body .token-title-row .token-name, .token .tab-body .token-row .token-name {
  font-size: 14px;
}
.token .tab-body .token-title-row .token-social, .token .tab-body .token-row .token-social {
  display: flex;
  gap: 8px;
}
.token .tab-body .token-title-row .token-social .tronlink-btn, .token .tab-body .token-row .token-social .tronlink-btn {
  font-size: 11px;
  background: #ff0000 !important;
  border: 0;
}
.token .tab-body .token-title-row .token-social .sun-btn, .token .tab-body .token-row .token-social .sun-btn {
  font-size: 11px;
  background: #fab240 !important;
  color: #000;
  padding-right: 7px !important;
  border: 0;
}
.token .tab-body .token-title-row .token-social button, .token .tab-body .token-row .token-social button {
  border: 0;
  display: flex;
  gap: 4px;
  background: #202020 !important;
  border: 1px solid #383838;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  font-weight: 600;
  padding: 3px 5px !important;
  font-size: 10px;
}
.token .tab-body .token-title-row .token-social button img, .token .tab-body .token-row .token-social button img {
  width: 15px;
}
.token .tab-body .token-row-one {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.token .tab-body .token-row-one button {
  width: 100%;
  background: #33d1d8;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  color: #000;
  font-weight: 600;
  height: 30px;
}
.token .tab-body .token-row-one button:active, .token .tab-body .token-row-one button:hover {
  background: #43e9f2;
}
.token .tab-body .bonding-progress {
  width: 100%;
  min-height: 10px;
}
.token .tab-body .token-row > div {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  align-items: center;
}
.token .tab-body .token-row > div .token-label {
  color: #71747a;
  font-size: 12px;
  width: 45%;
}
.token .tab-body .token-row > div .token-value {
  font-size: 12px;
  font-weight: 600;
  width: 55%;
  display: flex;
  gap: 3px;
  align-items: center;
}
.token .tab-body .token-row > div .token-value .tier-info {
  font-size: 11px;
  font-weight: 300;
  color: #71747a;
}
.token .tab-body .token-row > div .token-value .table-btn {
  background: #202020;
  border: 1px solid #383838;
  color: #fff;
  border-radius: 4px;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  line-height: 1;
  font-weight: 600;
}
.token .tab-body .token-row > div .token-value .table-btn svg {
  opacity: 0.55;
}
.token .tab-body .token-row > div .token-value .table-btn:hover, .token .tab-body .token-row > div .token-value .table-btn:active {
  background: #2b2b2b;
}
.token .tab-body .token-row > div .token-value .copy {
  height: 12px;
  width: 12px;
  padding: 0;
  margin: 0;
  position: relative;
}
.token .tab-body .token-row > div .token-value .copy img {
  height: 12px;
  width: 12px;
  padding: 0;
  margin: 0;
  position: relative;
  top: -5px;
}
.token .tab-body .bonding-progress .progres-bar-tab {
  width: 100%;
  background-color: rgba(93, 93, 93, 0.46);
  height: 8px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 12px;
}
.token .tab-body .bonding-progress .progres-bar-tab .point {
  background-color: #2d9863;
  height: 8px;
}
.token .tab-body .token-premium {
  background: #121214;
  background: linear-gradient(to right, rgba(0, 58, 98, 0.2784313725) 0%, rgba(1, 175, 142, 0.1098039216) 100%);
  border: 0;
  padding: 15px 20px 20px 20px;
  margin-top: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: calc(100% - 6px);
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
.token .tab-body .token-premium::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: linear-gradient(90deg, #0081DB, #01E9BD);
  z-index: -2;
  border-radius: 15px;
}
.token .tab-body .token-premium::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #121214;
  background: linear-gradient(to right, #181818 0%, #0e0e0e 100%);
  z-index: -1;
  border-radius: 13px;
}
.token .tab-body .token-premium .token-label {
  color: #fff !important;
  font-size: 11px !important;
}
.token .tab-body .token-premium button {
  margin-top: 5px;
  border-radius: 6px;
  background-color: #43e9f2;
  border: 0;
}
.token .tab-body .token-row.seperator,
.token .tab-body .bonding-progress.seperator {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(56, 56, 61, 0.24);
}/*# sourceMappingURL=mobileCoinItem.css.map */