.token-modal {
  padding-bottom: 2px !important;
  margin-bottom: 2px !important;
}
.token-modal .card-item {
  display: flex;
  background: #0d0d0f;
  border: 1px solid #292929;
  margin: 0 0 15px 0;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
}
.token-modal .card-item .card-item-col {
  width: 100%;
}
.token-modal .card-item .card-item-col .card-item-row .procent {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.token-modal .card-title-row {
  display: grid;
  grid-template-columns: 125px 1fr;
  grid-template-rows: 1fr;
  gap: 15px;
  grid-template-areas: ". .";
  width: 100%;
  margin-bottom: 15px;
}
.token-modal .card-title-row .token-img {
  height: 125px;
  background-position: center center;
  background-size: auto 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
}
.token-modal .card-title-row .token-info {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.token-modal .card-title-row .token-info .token-full-name-row {
  display: flex;
  justify-content: space-between;
}
.token-modal .card-title-row .token-info .token-full-name-row .title {
  font-size: 23px;
  font-weight: 600;
}
.token-modal .card-title-row .token-info .token-full-name-row .token-right {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.token-modal .card-title-row .token-info .token-full-name-row .token-right .social-btns {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.token-modal .card-title-row .token-info .token-full-name-row .token-right .social-btns .table-btn {
  width: 15px;
}
.token-modal .card-title-row .token-info .token-full-name-row .token-right .social-btns .table-btn:disabled {
  opacity: 0.2;
}
.token-modal .card-title-row .token-info .token-full-name-row .token-right .social-btns .table-btn img {
  width: 17px;
}
.token-modal .card-title-row .token-info .address-row {
  display: flex;
  gap: 35px;
  margin-top: -10px;
}
.token-modal .card-title-row .token-info .address-row .token-adress {
  display: flex;
  align-items: center;
  gap: 10px;
}
.token-modal .token-premium {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 15px;
}
.token-modal .token-premium .premium-row {
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}
.token-modal .token-premium .premium-row .premium-item {
  width: 49.1%;
  background: rgba(17, 24, 39, 0.34);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  line-height: 1;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.token-modal .token-premium .premium-row .premium-item .item-label {
  font-size: 15px;
  opacity: 0.65;
  color: #fff;
}
.token-modal .token-premium .premium-row .premium-item .item-value {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.token-modal .modal-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2px;
}
.token-modal .modal-row .param {
  background: #141414;
  width: 100%;
  padding: 6px 12px;
  border-radius: 1px;
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
}
.token-modal .modal-row .param .token-label {
  font-size: 11px;
  font-weight: 500;
}
.token-modal .modal-row .param .token-value {
  font-size: 13px;
  font-weight: 800;
}
.token-modal .to-left-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
}
.token-modal .table-btn {
  border: 0;
  background-color: #212227;
  color: #fff;
  cursor: pointer;
  padding: 2px 10px;
}
.token-modal .table-btn:hover {
  background-color: #1f2025;
}
.token-modal .token-label {
  opacity: 0.65;
  font-weight: 400;
}

.modal-cards-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 15px;
  grid-template-areas: ". .";
  align-items: start;
}
.modal-cards-row .card-item .card-item-col .token-title {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}
.modal-cards-row .card-item .card-item-col .token-title .token-icon > div {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center;
  border-radius: 35px;
}
.modal-cards-row .card-item .card-item-col .token-title .token-name {
  font-size: 15px;
}

.progres-bar {
  background: linear-gradient(270deg, #479a70 0%, #479a70 20%, #d8b744 40%, #d8b744 60%, #d04140 80%, #d04140 100%);
  width: 100%;
  height: 5px;
  border-radius: 10px;
  position: relative;
}
.progres-bar .point {
  position: absolute;
  background-color: #d9d9d9;
  border: 2px solid rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
  width: 10px;
  height: 10px;
  border-radius: 100px;
  top: -3px;
}/*# sourceMappingURL=tokenDetailsModal.css.map */